export const fleatoConfig = {
  indexName: "productdev",
  productEventIndexName: "eventdev",
  userIndexName: "userdev",
  artistIndex: "artistdev",
  opportunityIndex: "opportunitydev",
  payPalClientId:
    "AS8ZCAZZs73QCQBV_Jwu83sm-WViVgg3FanI_7mMD8kA6DxaLnjA1FhxnG8PUNoGTF1RojydlGOu1y9Y",
  gtmId: "G-MLMTL6RJNS",
  facebookPixelId: "460897109290635",
  fleatoNFTGraphUrl:
    "https://api.thegraph.com/subgraphs/name/fleatomarket/fleato-nft-mumbai",
  bluetileGraphUrl:
    "https://api.thegraph.com/subgraphs/name/fleatomarket/bluetile",
  polygonRpcEndpoint:
    "https://polygon-mumbai.g.alchemy.com/v2/t8IsqAcOjU1JngSrRRoA6YkfiheXvJuM",
  ethereumRpcEndpoint:
    "https://eth-ropsten.alchemyapi.io/v2/Yh6Iu98KTJSXbHY_sx-SzCz1ROBoK78J",
  contractPhysicalSwap: "0xf22eb1088b095ca703d1B423Ee022f890525e903",
  contractUsdcToken: "0x90A49b254a017C4Fc2E22a732097d6d17752d855",
  contractFleatoToken: "0xaD2a6255264D1dEf7EbEC64FA531F247a215A549",
  contractWbtcToken: "0x4E6bd2B6bb8448aAF90C8D62baF83856FBEDc036",
  contractWethToken: "0x454B666432f76C1DF80867e6BDE8EaFe753D6230",
  contractWmaticToken: "0x9c3c9283d3e44854697cd22d3faa240cfb032889",
  contractFleatoNFT: "0xcBd79b9C07e43f179f284a9560E51A4B5A0e73b9",
  contractGnosisSingleton: "0xd9db270c1b5e3bd161e8c8503c55ceabee709552",
  contractGnosisFallbackLibrary: "0xf48f2b2d2a534e402487b3ee7c18c33aec0fe5e4",
  contractGnosisMultisendLibrary: "0xa238cbeb142c10ef7ad8442c6d1f9e89e07e7761",
  contractDaoMembershipBot: "0x1B26F4F9DaBc154890c63a52dA57092aD26cE062",
  contractPoster: "0x000000000000cd17345801aa8147b8D3950260FF",
  contractBaalSummoner: "0xa9EB82dD208738d897aB39cb69D28F1160CAA0b7",
  contractLootTemplate: "0x7a09868459b5708b77b776180abc52D925887c3C",
  contractSharesTemplate: "0x5ef5B16b0BD91f8A08B4502e4d668D6D03Af4D48",
  addressAdjudicator: "0x504E3610517E7AA4BCA0B860591633B120B2803d",
  contractSushV2Router02: "0x1b02dA8Cb0d097eB8D57A175b88c7D8b47997506",
  contractBlueTile: "0xE2d7b606b6b8745095A03726085d24Df5Ba6eA59",
  maticChainId: 80001,
  ethereumChainId: 3,
  gapiClientId:
    "894882295109-p31uebb18jcntuq9ag4gkjfvrdeafsfe.apps.googleusercontent.com",
  listingBucket: "gs://fleato-listing-dev",
  polygonNetworkName: "Matic",
  polygonRpcUrl: "https://polygon-rpc.com",
  blockExplorer: "https://polygonscan.com",
  websiteBaseUrl: "https://www.digilisa.com",
  cloudFunctionBaseUrl: "https://us-central1-fleato-dev.cloudfunctions.net",
  blogBaseUrl: "https://services.fleato.com",
  nftBaseUrl: "https://devnft.digilisa.com",
  polygonScanApiKey: "GEHIMH1KXQ18YRJAHGIUVVR1JJ58VSBN8M",
  storageBaseUrl: "https://devcdn.fleato.com",
  imageOptimizedUrls: [
    "https://firebasestorage.googleapis.com/v0/b/fleato-listing-dev",
    "https://devcdn.fleato.com",
  ], //, 'https://devcdn.fleato.com'
  mapBox:
    "pk.eyJ1Ijoic3BhcmtsZWUiLCJhIjoiY2wwdW1qejFvMG95ZTNwcGZ0ejJpeHd3aSJ9.SFxF-pIVI72x_BbT_xP75A",
  signupReward: 10,
  defaultRandomUUID: "000000000000000000000000000000000000",
  googleMapsApiKey: "AIzaSyArINdHSF-o60ncLOBDdQhJW_2uo2NjAqA",
};
