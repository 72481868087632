import React, { useState, useEffect, useContext, useRef } from "react";
import Link from "next/link";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import { FaExternalLinkAlt } from "react-icons/fa";
import useLogin from "./useLogin";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import animeLoading from "../../public/lottie/14117-crypto-loader.json";
import Lottie from "react-lottie";
import { UserContext } from "../../contexts/UserContext";

const schema = yup
  .object({
    verificationCode: yup.string()
      .matches(/^[0-9]+$/, "Must be only digits")
      .min(6, 'Must be exactly 6 digits')
      .max(6, 'Must be exactly 6 digits')
  })
  .required();

declare const window: Window &
  typeof globalThis & {
    grecaptcha: any
    recaptchaVerifier: RecaptchaVerifier,
    resendRecaptchaVerifier: RecaptchaVerifier,
  }

interface VerificationViewModel {
  verificationCode: string;
}

interface Props {
  qrButtonTheme?: boolean;
  phoneNumber: string;
  signingUp: boolean;
  signedIn: () => void;
  closeModal: () => void;
}

export default function VerificationCodeForm(props: Props) {
  const auth = getAuth();
  const { phoneNumber, signingUp, signedIn } = props;
  const {dbUser} = useContext(UserContext);

  const { register, handleSubmit, reset, watch, formState: { isDirty, errors, isValid } } = useForm<VerificationViewModel>({ mode: "onSubmit", resolver: yupResolver(schema), defaultValues: {} });

  const watchVerificationCode = watch("verificationCode");
  const [loading, setLoading] = useState(false);
  const [resendRequested, setResendRequested] = useState(false);
  const [resending, setResending] = useState(false);
  const { signInSendPhoneNumber, signInSendVerificationCode, resendVerificationCode, verificationCodeSent, verificationCodeError, clearVerificationError, verificationCodeResent } = useLogin();

  useEffect(() => {
    (async () => {
      setLoading(true);
      window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth);
      await window.recaptchaVerifier.render();
      setLoading(false);
      const result = await signInSendPhoneNumber(phoneNumber, window.recaptchaVerifier);
    })();
  }, []);

  useEffect(() => {
    clearVerificationError();
  }, [watchVerificationCode])

  const resendCode = async () => {
    await reset();
    setResendRequested(true);
    setResending(true);
  }

  useEffect(() => {
    if (resending) {
      (async () => {
        setLoading(true);
        window.resendRecaptchaVerifier = new RecaptchaVerifier('recaptcha-container1', {}, auth);
        await window.resendRecaptchaVerifier.render();
        setLoading(false);
        await resendVerificationCode(phoneNumber, window.resendRecaptchaVerifier);
        await setResending(false);
      })();
    }
  }, [resending]);

  const onSubmit = async (data: VerificationViewModel) => {
    setLoading(true);
    if (auth?.currentUser?.uid && auth?.currentUser?.providerData?.length > 0 && dbUser?.id && dbUser?.signInProviders?.length > 0) {
      props?.closeModal();
    } else {
      const success = await signInSendVerificationCode(data.verificationCode);
      if (success) {
        signedIn();
      } else {
        setLoading(false);
      }
    }
  }

  return (
    <div className="gtm-loginpopup">
      <div className="px-3 mb-3">
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3 className="text-center text-blue mb-4">Sign In / Sign Up</h3>
          <div id="recaptcha-container" hidden={verificationCodeSent} />
          {resendRequested && <div id="recaptcha-container1" hidden={verificationCodeResent} />}
          {!loading &&
            <>
              {<input
                hidden={!verificationCodeSent || (resendRequested && !verificationCodeResent)}
                id="verificationCode"
                name="verificationCode"
                type="tel"
                {...register("verificationCode")}
                className="form-control w-100 mt-4 my-4"
                maxLength={6}
                placeholder="Enter verification code sent to your phone"
              />
              }
              {errors.verificationCode && <p className={`errorMessage my-2`}>{errors.verificationCode.message}</p>}
              {!!verificationCodeError && <p className={`errorMessage my-2`}>{verificationCodeError}</p>}
              <span hidden={!verificationCodeSent || resendRequested} className="my-4">
                <Link href="/" passHref><a onClick={() => resendCode()}>Send me the code again</a></Link>
              </span>
              <button type="submit" className={`btn text-white text-uppercase w-100 my-2 pt-2 ${props.qrButtonTheme ? 'black-bg' : 'blue-bg'}`}>
                {signingUp ? "Sign up" : "Sign in"}
              </button>
            </>
          }
          {loading &&
            <Lottie height={100} width={100} options={{ loop: true, autoplay: true, animationData: animeLoading }} />
          }
        </form>
      </div>
      <div className="text-center terms" style={{ fontSize: "10px" }}>
        <p className="px-2 pt-2">
          By clicking SIGN IN or SIGN UP, you acknowledge that you have read and accepted the <Link href="/terms" passHref>
            <a target="_blank" rel="noreferrer"><span style={{ fontSize: "10px" }}>Terms of Service{" "}</span><FaExternalLinkAlt size={10} /></a>
          </Link>{" "}
          and{" "}
          <Link href="/privacy" passHref>
            <a target="_blank" rel="noreferrer"><span style={{ fontSize: "10px" }}>Privacy Policy{" "}</span><FaExternalLinkAlt size={10} /></a>
          </Link>
        </p>
      </div>
    </div>
  );
}
