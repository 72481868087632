
import logo from "../../public/images/blacklogo.svg";
import { memo, useContext } from "react";
import Link from "next/link";
import { useState, useEffect, useRef } from "react";
import { ReactSVG } from "react-svg";
import { UserContext } from "../../contexts/UserContext";
import { HeaderWrapper } from "../../styles/global-style";
import { DropdownMenuWrapper } from "../../components/menu/dropdown/dropdown-style";
import { DropdownMenu } from "../../components/menu/dropdown/dropdownMenu";
import { tagSellClicked } from "../../lib/events";
import { initials } from "../../util/util";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { BiCart } from "react-icons/bi";
import UseWindowSize from "../../hooks/helper/useWindowSize";
import { Branding } from "../../models/common";
import { ArtistProfile } from "../../models/user";
import DebouncedSearchBox from '../../components/search/debounced-search';
import Image from "next/image";
import { CategoriesContext } from "../../contexts/CategoriesContext";
import { ArtistContext } from "../../contexts/ArtistContext";
import freshArtsLogo from '../../public/images/assets/fresharts_logo.png';
import { useRouter } from "next/router";
import { OrderContext } from "../../contexts/OrdersContext";
import { AgenciesContext } from "../../contexts/AgenciesContext";
import moment from "moment";

interface Props {
  branding: Branding
  openRedeemModal: () => void;
  openModal: (referralCode?: string, isSigningUp?: boolean) => void;
  artist: ArtistProfile,
  setClearSearchBox: (b: boolean) => void;
  clearSearchBox: boolean;
}

const Header = (props: Props) => {

  const { user, dbUser, isVerifiedUser } = useContext(UserContext);
  const { isSeller } = useContext(ArtistContext);
  const { agencies } = useContext(AgenciesContext);
  const { isRerender, setIsRerender } = useContext(CategoriesContext);
  const [scrolled, setScrolled] = useState(false);
  const { branding, openRedeemModal } = props;
  const [toggleDropDown, setToggleDropDown] = useState(false);
  const screenProps = UseWindowSize();
  const router = useRouter();
  const { inCart } = useContext(OrderContext);
  const marketPlace = (router?.asPath?.split("/")?.[1] === "m" && router?.asPath?.split("/")?.[2] === "marketplace") ? router?.asPath?.split("/")?.[3] : "fleato";
  const myAgency = agencies?.find(item => item?.id === marketPlace);

  const [localUser, setLocalUser] = useState({
    userName: "",
    email: "",
    phone: "",
  })

  useEffect(() => {
    (async () => {
      const userName = await localStorage.getItem("userName");
      const email = await localStorage.getItem("email");
      const phone = await localStorage.getItem("phone");
      await setLocalUser({
        userName: (userName != undefined && userName != "undefined") ? userName : "",
        email: (email != undefined && email != "undefined") ? email : "",
        phone: (phone != undefined && phone != "undefined") ? phone : "",
      })
    })()
  }, [dbUser])

  const [localAgencyDetails, setLocalAgencyDetails] = useState({
    agencyName: "",
    agencyLogo: "",
    agencyTimeout: "",
  });

  useEffect(() => {
    (async () => {
      const agencyName = await localStorage.getItem("agencyName");
      const agencyLogo = await localStorage.getItem("agencyLogo");
      const agencyTimeout = await localStorage.getItem("agencyTimeout");

      if(agencyTimeout?.length > 0 && agencyTimeout != "undefined"){
        const hourDifference = moment.duration(moment().diff(new Date(agencyTimeout)))?.hours();

        if(hourDifference > 1){
          await localStorage.removeItem("agencyName");
          await localStorage.removeItem("agencyLogo");
          await localStorage.removeItem("agencyTimeout");
        }else{
          await setLocalAgencyDetails({
            agencyName: (agencyName != undefined && agencyName != "undefined") ? agencyName : "",
            agencyLogo: (agencyLogo != undefined && agencyLogo != "undefined") ? agencyLogo : "",
            agencyTimeout: (agencyTimeout != undefined && agencyTimeout != "undefined") ? agencyTimeout : "",
          })  
        }  
      }
    })()
  }, [user])  

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          if (toggleDropDown) { toggleDropDownMenu() }

        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, toggleDropDown]);
  }


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener("scroll down", handleScroll);
    };
  })

  const signInSignUpProfileUpdate = (isSigningUp: boolean) => {
    if (user === null && (!localUser?.phone || localUser?.phone?.length === 0)) {
      props.openModal(undefined, isSigningUp);
    }
    else {
      toggleDropDownMenu();
    }
  }

  const signInSignUp = (isSigningUp: boolean) => {
    props.openModal(undefined, isSigningUp);
  }

  const [brandLogo, setBrandLogo] = useState("");

  useEffect(() => {
    if (!!branding?.brandName && !!props?.artist && !!props?.artist?.logo) {
      setBrandLogo(props?.artist?.logo);
    }
  }, [branding])

  const toggleDropDownMenu = () => {
    setToggleDropDown(!toggleDropDown);
  }
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleScroll = () => {
    const offset = window.scrollY;

    if (offset > 0) {
      setScrolled(true);
    }
    else {
      setScrolled(false);
    }
  }

  let toggleScroll = [''];
  if (scrolled) {
    toggleScroll.push('scrolled');
  }


  const logoUrl = localAgencyDetails?.agencyName?.length > 0 ? `/m/marketplace/${localAgencyDetails?.agencyName}` :  (marketPlace != "fleato" ? `/m/marketplace/${marketPlace}` : branding?.brandName == "fleato" ? "/" : `/m/a/${branding?.brandName}`);
  
  return (
    <HeaderWrapper className={toggleScroll.join(" ")}>
      <header>
        <div className="container py-3 px-lg-4 max-w-100">
          <div className="row align-items-center">
            <div className="col-12 col-md-12 col-lg-3 text-right order-lg-2 logo-wrapper">
              <div className="d-flex align-items-center w-auto d-lg-none">
                <Link href={logoUrl} passHref >
                  <a className="d-flex align-items-start" onClick={() => {setIsRerender(!isRerender) }}>
                    {localAgencyDetails?.agencyLogo?.length > 0 ?
                      <Image src={localAgencyDetails?.agencyLogo} alt='logo' height={50} width={100} />
                      : router?.pathname == '/e' ?
                      <Image src={freshArtsLogo.src} alt='logo' height={50} width={100} />
                      : (marketPlace != "fleato" && myAgency?.preferences?.logoLink) ?
                        <Image src={myAgency?.preferences?.logoLink} alt='logo' height={50} width={100} />
                        : <>
                          <ReactSVG src={logo.src} alt="Logo" />
                          <p className="mb-0 mx-2 " style={{ fontSize: 23, fontWeight: 600 }}>Fleato</p>
                        </>}
                  </a>
                </Link>
              </div>
              <ul className="list-unstyled d-flex  justify-content-end align-items-center font-md m-0" ref={wrapperRef}>

                {branding?.brandName == "fleato" && marketPlace == "fleato" ? <li className="px-3">
                  <Link href={'/my-shop'} passHref>
                    <a className="bold"><span onClick={() => {tagSellClicked(user?.uid ?? "guest")}} >Sell</span></a></Link>
                </li> : <></>}

                {screenProps.windowSize.width > 768 && <li className="p-1 cursor-pointer mx-2 ">
                  <Link href={myAgency?.preferences?.helpPageLink ? myAgency?.preferences?.helpPageLink : "/help"} passHref><a data-title="Help" className="bold title-tip"><AiOutlineQuestionCircle size={24} /></a></Link>
                </li>}

                <li className="px-3 pe-0 pe-md-2 cursor-pointer" onClick={(e) => { e?.preventDefault(); signInSignUpProfileUpdate(false) }}>
                  {toggleDropDown && <DropdownMenuWrapper
                    className={"visible"}
                  >
                    {
                      <DropdownMenu openRedeemModal={openRedeemModal} isVerifiedUser={isVerifiedUser} isSeller={isSeller} signInSignUp={signInSignUp} myAgency={myAgency} />
                    }
                  </DropdownMenuWrapper>}
                  <>
                    {(user === null && (localUser?.phone?.length === 0 || !localUser?.phone)) &&
                      <> <Link href="/" passHref>
                        <a style={{ whiteSpace: "nowrap" }} onClick={(e) => { e?.preventDefault(); signInSignUpProfileUpdate(false) }}>Login</a>
                      </Link>
                        <span style={{ pointerEvents: 'none', fontWeight: 'bold' }} onClick={(e) => e.preventDefault()} className="mx-1 mx-lg-2">|</span>
                        <Link href="/" passHref>
                          <a style={{ whiteSpace: "nowrap" }} onClick={(e) => { e?.preventDefault(); signInSignUpProfileUpdate(true) }}>Sign up</a>
                        </Link></>
                    }
                    {(user !== null || localUser?.phone?.length > 0) && (dbUser?.photoUrl || user?.photoURL) &&
                      <Image
                        src={dbUser?.photoUrl ?? user?.photoURL ?? ""}
                        alt="User-Photo"
                        width={32} height={32}
                        className="rounded-circle"
                      />
                    }
                    {(user !== null || localUser?.phone?.length > 0) && !dbUser?.photoUrl && !user?.photoURL &&
                      <a className={(dbUser?.handle || localUser?.phone?.length > 0) ? "displayName" : ""}>{initials(dbUser?.name ?? dbUser?.handle ?? localUser?.userName)}</a>
                    }
                    {(user !== null || localUser?.phone?.length > 0) && !dbUser?.photoUrl && !user?.photoURL && !dbUser?.name && !dbUser?.handle && !localUser?.userName &&
                      <Image
                        src="/user-profile-svgrepo-com.svg"
                        alt="User-Profile"
                        width={32} height={32}
                        className="rounded-circle"
                      />
                    }
                  </>
                </li>
                {(isVerifiedUser || localUser?.phone?.length > 0) && <>
                  <li className="p-1 cursor-pointer mx-1">
                    <Link href="/my-cart" passHref><a> <BiCart size={24} color="#000000" /> {inCart?.length > 0 ? <span className="position-absolute bg-danger text-white text-center m-0 pt-1" style={{ transform: "translate(-8px,-8px)", fontSize: 10, width: 20, height: 20, borderRadius: '50%' }}>{inCart?.length}</span> : undefined}</a></Link>
                  </li></>}
              </ul>
            </div>
            <div className="col-md-12 col-lg-9 order-lg-1">
              <div className="float-start w-auto pe-2 me-1 mt-lg-2 d-none d-lg-block logo">
                <Link href={logoUrl} passHref>
                  <a className="d-flex align-items-start">
                    {localAgencyDetails?.agencyLogo?.length > 0 ?
                      <Image src={localAgencyDetails?.agencyLogo} alt='logo' height={50} width={100} />
                      : router?.pathname == '/e' ?
                      <Image src={freshArtsLogo.src} alt='logo' height={50} width={100} />
                      : (marketPlace != "fleato" && myAgency?.preferences?.logoLink) ?
                        <Image src={myAgency?.preferences?.logoLink} alt='logo' height={50} width={100} />
                        : <>
                          <ReactSVG src={logo.src} alt="Logo" />
                          <p className="mb-0 mx-2 " style={{ fontSize: 23, fontWeight: 600 }}>Fleato</p>
                        </>}
                  </a>
                </Link>
              </div>
              <div className={`${screenProps.windowSize.width >= 720 && 'row'} mt-15`}>
                <div
                  className="searchwrapper col-10 col-md-10 p-lg-0 col-lg-10 mx-auto mx-lg-0"
                >
                  <DebouncedSearchBox clearSearchBox={props.clearSearchBox} setClearSearchBox={props.setClearSearchBox} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </HeaderWrapper>
  );
};
export default memo(Header);

