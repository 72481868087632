export const firebaseConfig = {
  apiKey: "AIzaSyCIRtkuRqjySOUxQGZ7r9s1qmfYDiPIrho",
  authDomain: "fleato-dev.firebaseapp.com",
  databaseURL: "https://fleato-dev-default-rtdb.firebaseio.com",
  projectId: "fleato-dev",
  storageBucket: "fleato-dev.appspot.com",
  messagingSenderId: "894882295109",
  appId: "1:894882295109:web:5cfa53ec564ad076c8222c",
  measurementId: "G-MLMTL6RJNS",
  webPushKeyPair:
    "BL9YMn4yF6XvNXt9SXRtfnt67d0fW99nVmRK3CysM9fjadW9nzFakSl1k0_8eNnHzAVIhlEcVIWFuy2-blYCPZ4",
};
