import { collection, doc, onSnapshot, query, setDoc, where } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { ChatTopic } from '../../models/chat';
import { db, auth } from '../../firebaseutil';
import * as Sentry from '@sentry/nextjs'

// Initialize Firebase
const COLLECTION = {
    CHAT: "chat",
    BLOCKED_USERS: "block",
    BLOCKED_USERS_FOR_USER: "blocked"
}

export default function useChatTopics( userId: string ) {
    const [topics, setTopics] = useState<ChatTopic[]>([]);
    const [blockedUsers, setBlockedUsers] = useState<any[]>([]);
    // console.log("topics", topics);
    
    useEffect(() => {
        if(!!!auth?.currentUser?.uid){
          setBlockedUsers([]);
          setTopics([]);
        }
    }, [auth?.currentUser?.uid])

    useEffect(() => {
        if (!auth.currentUser?.uid)
            return;
        const chatsQuery = query(collection(db, COLLECTION.CHAT), where('partyIds', 'array-contains', auth.currentUser?.uid));
        const unsubscribe = onSnapshot(chatsQuery, (querySnapshot) => {
            const returnArray: ChatTopic[] = [];
            querySnapshot.forEach(item => {
                returnArray.push(item.data() as ChatTopic);
            });
            //prevTopics => [...new Set([...prevTopics, ...returnArray])].sort((a,b) => b.createdAt > a.createdAt ? 1 : -1)
            const sortedTopics = returnArray.sort((a, b) => b.lastMessageAt > a.lastMessageAt ? 1 : -1);
            let filterdTopics;
            filterdTopics = sortedTopics.filter(t => !t.partyIds.some(p => blockedUsers.includes(p)));
            // if(filterdTopics.length !== sortedTopics.length) {
            //     console.log(`blocked ${sortedTopics.length - filterdTopics.length} topics`);
            // } else {
            //     console.log(`getTopics not blocking any of ${filterdTopics.length} topics`);
            // }
            setTopics(filterdTopics);
        }, err => {
            Sentry.captureException(err);
            console.log(`Encountered error: ${err}`);
        });
        return unsubscribe;
    }, [auth.currentUser?.uid]);

    useEffect(()=> {
        if (!userId)
            return;
        (async () => updateBlockedUsers())();
    }, [userId])

    const updateBlockedUsers = async () => {
        if(!userId)
            return;
        // const blockedUsersSnapshot1 = await db.collection(COLLECTION.BLOCKED_USERS).doc(COLLECTION.BLOCKED_USERS).collection(COLLECTION.BLOCKED_USERS).get();
        const blockedUsersSnapshot = await query(collection(db, COLLECTION.BLOCKED_USERS, userId, COLLECTION.BLOCKED_USERS_FOR_USER));
        const b: any[] = [];
        const getBlockedUsers = onSnapshot(blockedUsersSnapshot, (snapshot) => {
            b.push(snapshot);
        },(err)=>{Sentry.captureException(err);console.log(err)})
        setBlockedUsers(b);
    }

    const setTopicToRead = async(buyerId: any, sellerId: any, productId: any) => {
        console.log(buyerId, sellerId, productId);
       try {
        const chatsQuery = await setDoc
                        (doc
                        (db, COLLECTION.CHAT, `${buyerId}|${sellerId}|${productId}`), 
                        {
                            readBuyer: true,
                            readSeller: true,
                        }, {merge: true});
                    }
                catch(err) {
                    // console.log({setShortUrlData: e});      
                    Sentry.captureException(err);console.log(err)      
                }
    }

    const unreadTopicsCount = topics?.filter(t => (auth?.currentUser?.uid == t.partyIds[0] && !t.readBuyer) || (auth?.currentUser?.uid == t.partyIds[1] && !t.readSeller)).length;
    
    return { topics, unreadTopicsCount, blockedUsers, updateBlockedUsers, setTopicToRead };
}