import { useState, useEffect, useCallback } from 'react';
import Filter from "bad-words";
import { collection, doc, onSnapshot, query, setDoc, where, writeBatch } from 'firebase/firestore';
import { ChatMessage, ChatTopic, ReadStatus } from '../../models/chat';
import { db, auth } from '../../firebaseutil';
import * as Sentry from '@sentry/nextjs'

const filter = new Filter()
// Initialize Firebase

const COLLECTION = {
    CHAT: "chat",
    MESSAGES: "messages",
}

export default function useChatMessages({userId, buyerId, sellerId, productId}: {userId: string, buyerId: string, sellerId: string, productId: string}) {
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [isBlocked, setIsBlocked] = useState(false);

    useEffect(() => {
        if(!!!auth?.currentUser?.uid){
          setMessages([]);
        }
      },[auth?.currentUser?.uid]);
    
    useEffect(() => {
        if(!buyerId || !sellerId || !productId || !auth.currentUser?.uid)
            return;
        const messagesQuery = query(collection(db, `${COLLECTION.CHAT}`, `${buyerId}|${sellerId}|${productId}`, `${COLLECTION.MESSAGES}`), where('partyIds', 'array-contains', `${auth.currentUser?.uid}`));
        const unsubscribe = onSnapshot(messagesQuery, (documentSnapshot) => {
            const returnArray: ChatMessage[] = [];
            documentSnapshot.forEach(item => {
                    returnArray.push(item.data() as ChatMessage);
                });
                setMessages(returnArray.sort((a,b) => b.createdAt - a.createdAt));
            }, err => {
                Sentry.captureException(err);
                console.log(`Encountered error: ${err} trying to access ${buyerId}|${sellerId}|${productId} for user`,  err);
            });
        return unsubscribe;
    }, [auth.currentUser?.uid, buyerId, sellerId, productId]);

    const send = useCallback(async ({msg = [], prevCount = 0, buyerName, sellerName, productImageUrl = "", partyImageUrls = [], productName}: {
        msg: {text: string, id: string}[], prevCount: number, buyerName: string, sellerName: string, productImageUrl: string, partyImageUrls: string[], productName: string}) => {
    const batch = writeBatch(db);
        console.log("msg", msg);
        if(msg?.[0]?.text)
            msg[0].text = filter.clean(msg?.[0]?.text);
        const isoTime = new Date().toISOString()
        const topicRef = doc(db, COLLECTION.CHAT , `${buyerId}|${sellerId}|${productId}`);
        const updatedTopic: ChatTopic = {
            partyIds: [buyerId, sellerId],
            partyNames: [buyerName, sellerName],
            readBuyer: userId == buyerId ? true : false, 
            readSeller: userId == buyerId ? false : true, 
            buyerNotified: userId == buyerId ? true : false,
            sellerNotified: userId == buyerId ? false : true, 
            productName,
            messageCount: prevCount + msg?.length,
            lastMessage: msg[0]?.text || '',
            lastMessageFrom: msg[0]?.id || '',
            lastMessageAt: isoTime,
            productId,
            partyImageUrls,
            productImageUrl,
            productSlug: productId
            };
        console.log("set topic", updatedTopic);
       
        batch.set(topicRef, updatedTopic, {merge: true});

        const messageRef = doc(db, COLLECTION.CHAT , `${buyerId}|${sellerId}|${productId}`, COLLECTION.MESSAGES, isoTime);
        const message: ChatMessage = {
            ...msg[0],
            partyIds: [buyerId, sellerId],
            createdAt: new Date().getTime()
          };
        batch.set(messageRef, message);
        console.log("set message", message);

        await batch.commit();
    }, [buyerId, sellerId, productId])

    const markAsRead = () => {
       try{
         const topicRef = doc(db, COLLECTION.CHAT , `${buyerId}|${sellerId}|${productId}`);

        var read: ReadStatus ={
            readBuyer: buyerId === userId,
            readSeller: sellerId === userId
        };

        var setWithMerge = setDoc(topicRef, {...read}, {merge: true});
    }catch(err) {
        // console.log({setShortUrlData: e});      
        Sentry.captureException(err);
        console.log(err);    
    }
    };


    return {messages, send, markAsRead};
}